<template>
    <el-input
        :type="type || 'text'"
        v-model="value"
        @focus="focused = true"
        @blur="focused = false"
        @input="emitValue($event)"
        @keypress="NumbersOnly()"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        autocomplete="off"
    />
</template>

<script>
/**
 * @author Irakli Kandelaki
 */

import { ElInput } from "element-plus";
export default {
    emits: ["valueChanged"],
    props: [
        "clear",
        "disabled",
        "maxlength",
        "type",
        "defaultValue",
        "placeholder",
    ],
    components: {
        ElInput,
    },
    watch: {
        clear: {
            immediate: true,
            handler: function () {
                if (this.clear) {
                    this.value = "";
                }
            },
        },
    },
    data() {
        return {
            focused: false,
            value: "",
        };
    },
    methods: {
        NumbersOnly(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        emitValue(event) {
            this.$emit("valueChanged", event);
        },
    },
    mounted() {
        if (this.defaultValue) {
            this.value = this.defaultValue;
        }
    },
    watch: {
        "$props.defaultValue": {
            immediate: true,
            handler(ns) {
                if (ns) {
                    this.value = ns;
                } else {
                    this.value = "";
                }
            }
        }
    },
};
</script>
